import { ComponentType } from 'react'

export const getIcon = (iconName: string): ComponentType => {
  if (!iconName) return

  try {
    const Icon = require(`@telus-uds/palette-allium/build/web/icons/${iconName}.js`)
    return Icon
  } catch (e) {
    return undefined
  }
}
