import { IButtonLinkFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'
import { mapSpacer } from 'src/siteBuilder/renderer/contentful/mapSpacer'
import { mapModal } from '../../../renderer/contentful/mapModal'

// Component
import ButtonLink from './ButtonLink'

const ContentfulButtonLink = withContentful<IButtonLinkFields>(ButtonLink, {
  spacer: mapSpacer,
  baseModal: mapModal,
  analyticsUrlUsage: (props) => props.fields.analyticsUrlUsage,
})

export default ContentfulButtonLink
