import { IBaseModal, IButtonLinkFields, ICalloutFields, IChevronLinkFields, ILinkFields } from '@/contentful-types'
import { Entry } from 'contentful'

type MappedModal = {
  id: IBaseModal['sys']['id']
  entryTitle: IBaseModal['fields']['entryTitle']
  heading: IBaseModal['fields']['heading']
  subHeading: IBaseModal['fields']['subHeading']
  components: IBaseModal['fields']['components']
  modalMaxWidth: IBaseModal['fields']['modalMaxWidth']
  disableAnalytics: IBaseModal['fields']['disableAnalytics']
}

export const mapModal = (
  props: Entry<IButtonLinkFields | IChevronLinkFields | ILinkFields | ICalloutFields>
): MappedModal => {
  if (!props?.fields?.baseModal?.fields) return null

  return {
    id: props.fields.baseModal?.sys?.id,
    entryTitle: props.fields.baseModal?.fields?.entryTitle,
    heading: props.fields.baseModal?.fields?.heading,
    subHeading: props.fields.baseModal?.fields?.subHeading,
    components: props.fields.baseModal?.fields?.components,
    modalMaxWidth: props.fields.baseModal?.fields?.modalMaxWidth,
    disableAnalytics: props.fields.baseModal?.fields?.disableAnalytics,
  }
}
