import { ISpacerFields } from 'contentful-types'
import { SpacerProps } from 'src/siteBuilder/blocks/core/Spacer/Spacer'

export const mapSpacer = (props: any): SpacerProps => {
  if (!props?.fields?.spacer?.fields) {
    return undefined
  }

  const { xs, sm, md, lg, xl } = props?.fields?.spacer?.fields as ISpacerFields
  return { xs, sm, md, lg, xl }
}
